import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlankBook from "../images/blank-book.jpg"
import { useStaticQuery, graphql } from "gatsby"

export default function MyBooks(arg) {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { type: { eq: "project" }, draft: { ne: true } } }
        limit: 10
      ) {
        edges {
          node {
            excerpt(pruneLength: 250)
            html
            id
            fields {
              slug
            }
            frontmatter {
              date
              type
              title
              description
              tags
            }
          }
        }
      }
    }
  `)
  return (
    <Layout cssClass="card">
      <SEO title="My Projects"></SEO>
      {data.allMarkdownRemark.edges.map(({node}, i) => (
        <article class="paper">
          <h2> {node.frontmatter.title} </h2>
          <img
            class={"thirty-split rounded " + (i % 2 === 0 ? "left" : "right")}
            src={BlankBook}
            alt=""
          />

          {node.frontmatter.tags &&
            node.frontmatter.tags.map(tag => (
              <span className="tag"> {tag} </span>
            ))}

          <p>{node.excerpt}</p>
        </article>
      ))}
    </Layout>
  )
}
